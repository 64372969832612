import React, { useState } from "react";
import { HeaderComp } from "../../components/HeaderComp";
import { AboutUsTopLineComp } from "../../components/AboutUsTopLineComp";
import { OurStoryComp } from "../../components/OurStoryComp";
import { EssentialValuesComp } from "../../components/EssentialValuesComp";
import { OurPeopleComp } from "../../components/OurPeopleComp";
import { NewsComp } from "../../components/NewsComp";
import { FooterComp } from "../../components/FooterComp";
import i18n from "../../i18n";
import DocumentMeta from "react-document-meta";
import { BeleafComponent } from "../../components/BeleafComponent";

export const AboutPage = () => {
  const [language, setlanguage] = useState(i18n.language);
  i18n.on("languageChanged", (lng) => {
    setlanguage(lng);
  });
  const meta = {
    title: 'About',
    description: 'Beleaf Farms is a leading provider of fresh and healthy food products. Our mission is to bring the best and most nutritious products to your table, while supporting local farmers and promoting sustainable agriculture practices.',
    canonical: 'http://beleaf.co.id/about',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  }
  return (
    <DocumentMeta {...meta}>

      <div className="w-screen h-screen overflow-x-hidden">
        <HeaderComp changeLanguage={(lng) => i18n.changeLanguage(lng)} />
        <AboutUsTopLineComp />
        <OurStoryComp />
        <BeleafComponent />
        <EssentialValuesComp />
        {/* <OurPeopleComp /> */}
        
        <NewsComp />
        <FooterComp />
      </div>
    </DocumentMeta>

  );
};
