import React, { useState } from "react";
import { HeaderComp } from "../../components/HeaderComp";
import { ProductsComp } from "../../components/ProductsComp";
import { FooterComp } from "../../components/FooterComp";
import i18n from "../../i18n";
import { CareerTopLineComp } from "../../components/CareerTopLineComp";
import { CareerComp } from "../../components/CareerComp";
import { BeleafComponent } from "../../components/BeleafComponent";

export const CareerPage = () => {
  const [language, setlanguage] = useState(i18n.language);
  i18n.on("languageChanged", (lng) => {
    setlanguage(lng);
  });

  return (
    <div className="w-screen h-screen overflow-x-hidden">
      <HeaderComp
        changeLanguage={(lng) => i18n.changeLanguage(lng)}
        language={language}
      />
      <CareerTopLineComp />
      
      <CareerComp />
      <FooterComp />
    </div>
  );
};
