import React from "react";
import logoImage from "../assets/beleaf_logo.png";
import linkedinImage from "../assets/linkedin.png";
import instagranImage from "../assets/instagram.png";
import appDownload from "../assets/download_apk.png";
import badges1 from "../assets/Halal_Indonesia-dark green.png";
import badges2 from "../assets/haccp logo dark green.png";

import { Link } from "react-router-dom";
import { localStorageNavOptions } from "../config/localStorage";
import i18next from "i18next";
import { BsEasel } from "react-icons/bs";

export const FooterComp = () => {
  return (
    <div className="bg-white ">
      <div className="py-[3rem]  px-[8rem]">
        <div className="grid grid-cols-4 gap-8">
          <div className="flex">
            <div>
              <img className=" mt-[0.5rem] h-[4rem]" src={logoImage} alt="..." />
              {/* <p className=" font-Nunito text-sm font-bold mt-3">{i18next.t("Company")}</p> */}
              <ul className="text-sm mt-8">
                {localStorageNavOptions.map((item) => {
                  return (
                    <Link key={item.id} to={item.path}>
                      <li className="mb-3 text-md hover:underline">
                        {i18next.t(item.name)}
                      </li>
                    </Link> 
                  );
                })}
              </ul>
              <div className="lg:flex flex-row justify-between w-[40%] mr-10 mt-10 pr-[2rem]">
                <a href="https://www.linkedin.com/company/beleaf-farms-indonesia">
                  <div className="flex ">
                    <img
                      src={linkedinImage}
                      className="w-[20px] h-[20px] mr-2"
                    />
                    <p className="hidden lg:flex">LinkedIn</p>
                  </div>
                </a>
                <a
                  className="ml-2"
                  href="https://www.instagram.com/beleaffarms/?hl=en"
                >
                  <div className="flex">
                    <img
                      src={instagranImage}
                      className="w-[20px] ml-[2rem] h-[20px] mr-2"
                    />
                    <p className="hidden lg:flex">Instagram</p>
                  </div>
                </a>
              </div>
              <div>

                <h1 className="mt-6 font-Nunito font-bold text-xl">{i18next.t("Download Apk")}</h1>
                <p className="mt-2 font-Nunito  text-base leading-7 text-[#808080] tracking-wide w-[90%]">{i18next.t("Download diss")}</p>
                <a href=" http://bit.ly/tjp-android"><img className="mt-3" src={appDownload} alt={"..."}></img></a>
              </div>
            </div>
          </div>
          <div className="flex  col-span-2 lg:pr-20  ">
            <div>
              <p className=" mt-[0.5rem] font-Nunito font-bold text-start lg:text-start lg:text-[32px]">
                {i18next.t("About Us")}
              </p>
              <div className="mt-10 md:text-start  font-medium">
                <p className="font-Nunito text-base leading-7 text-[#808080] tracking-wide">
                  <span className="font-bold  text-lg">Beleaf Farms </span>
                  {i18next.t("Beleaf Farms (PT BELEAF KEBUN INDONESIA)")}
                </p>
                <p className="font-Nunito mt-5 text-base leading-7 text-[#808080] tracking-wide">
                  {i18next.t("As a company that is committed to quality")}
                </p>
                <p className="font-Nunito mt-5 text-base leading-7 text-[#808080] tracking-wide">
                  {i18next.t("We combine the latest advancements")}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="font-Nunito mt-[0.5rem] font-bold lg:text-[2rem]">
              {i18next.t("Come Say Hi")}
            </p>
            <p className="font-Nunito text-base mt-10 text-[#808080] tex-[0.8rem]   tracking-wide">
              PT BELEAF KEBUN INDONESIA
            </p>
            <div className="mt-5 ">
              <p className="font-Nunito mt-5 text-base leading-7 text-[#808080] tracking-wide">
              Beleaf Head Office 
              <br />
              Menara Ravindo
              <br />
              Jl. Kebon Sirih No.75 RT1/RW1
              <br />
              Kebon Sirih, Menteng
              <br />
              Jakarta Pusat 10340

              </p>
              {/* <p className="text-sm mt-3 tex-[1rem]">
                {i18next.t("Beleaf Product and Tech Office")}
              </p> */}
            </div>

          </div>

          

        </div>
        
      </div>
      <div className="w-full h-[13rem] bg-[#F9F9F9] flex items-center">
          <div className=" flex w-[12.5rem] h-[5rem] flex-col ml-[8rem]">
             <div className="w-full h-1/2 ">
              <p className="font-Nunito text-2xl">Certifications for</p>
             </div>
             <div className=" w-full h-1/2">
              <p className="font-black font-Nunito text-[#40AE49] text-3xl">Beleaf Farms</p> 
             </div>
          </div>
          <div className="ml-[6rem] flex ">
            <img className="h-[7rem]" src={badges1}>
            </img>
            <img className="h-[8rem]" src={badges2}>
            </img>

          </div>
        </div>
      <div className="bg-[#10312B] py-5">
        <p className="text-sm  font-light font-Nunito text-center text-white">
          PT BELEAF KEBUN INDONESIA ©2023 All Rights Reserved
        </p>
      </div>
    </div>
  );
};
