import React from 'react'

import i18next from "i18next";

export const BeleafComponent = () => {
  return (
    <div>
        <div className='mt-[-3rem]'>
        <div className="bg-[#40AE49] pt-[2rem] rounded-t-[50px]">
            <p className="text-white text-[30px]  text-center font-Nunito pb-[4rem] leading-7 tracking-wide"> {i18next.t("Our6")} <span className="font-extrabold">{i18next.t("Values1")}</span></p>
            <div className="flex justify-center">
              <div className="bg-[#ffffff40] w-[18%] rounded-tl-[100px] rounded-lg shadow-xl">
                <p className="text-center font-extrabold text-[70px] text-white  leading-2">L</p>
                <p className="text-center text-[20px] text-white font-semibold  font-Nunito  mt-[-10%]">{i18next.t("Learn")}</p>
                <p className="text-white  font-Nunito text-[17px] font-thin pl-3 pr-3 leading-6 ">{i18next.t("Improve")}</p>
              </div>
              <div className="bg-[#ffffff40] w-[20%] ml-6  rounded-lg shadow-xl">
                <p className="text-center text-[70px] text-white  font-extrabold  tracking-wide">E</p>
                <p className="text-center text-[20px] text-white font-semibold font-Nunito mt-[-8%]">{i18next.t("EXCELLENCE")}</p>
                <p className=" text-white  font-Nunito pl-3 text-[17px] font-thin pr-3 leading-6 pb-[1rem]">{i18next.t("operational")} {i18next.t("customers")}</p>
              </div>
            </div>
            <div className="flex justify-center mt-6 pb-[8rem]">
              <div className="bg-[#ffffff40] w-[20%] rounded-lg shadow-xl">
                <p className="text-center font-extrabold text-[70px] text-white  leading-2 tracking-wide">A</p>
                <p className="text-center text-[20px] text-white font-semibold font-Nunito  mt-[-8%]">{i18next.t("ACTION-ORIENTED")}</p>
                <p className=" text-white  font-Nunito pl-3 text-[17px] font-thin pr-3 leading-6">{i18next.t("Prioritize")}</p>
              </div>
              <div className="bg-[#ffffff40] w-[18%]  ml-6 rounded-br-[100px] rounded-lg shadow-xl">
                <p className="text-center font-extrabold text-[70px] text-white  leading-2 tracking-wide">F</p>
                <p className="text-center text-[20px] text-white font-semibold font-Nunito  mt-[-10%]">{i18next.t("Farmers Focused")}</p>
                <p className=" text-white  font-Nunito text-[17px] font-thin pl-3 pr-3 leading-6 pb-[1rem]">{i18next.t("farmers")}</p>
              </div>
            </div>
          </div>
          </div> 
    </div>
  )
}
